import {useEffect} from "react";
import FixedBottomBar from "../components/bottom";
import {HeaderSection} from "../components/header";
import MainSection from "../components/main";
import ParticlesBackground from "../components/particles";
import {ScreenSection} from "../components/screens";
import {copyToClipboard} from "../lib/utils";
import Adjust from "@adjustcom/adjust-web-sdk";

import {useSearchParams} from "react-router-dom";

const HomePage = () => {
  const [searchParams] = useSearchParams();

  function trackEvent(inviteCode: string | null) {
    Adjust.waitForWebUUID()
      .then(async webUUID => {
        Adjust.trackEvent({
          eventToken: "ra3bi2",
          callbackParams: [
            {key: "inviteCode", value: inviteCode || ""},
            {key: "webUUID", value: webUUID || ""}
          ]
        });
      })
      .catch(error => console.error("API call error:", error));
  }

  useEffect(
    () => {
      const inviteCode = searchParams.get("invite");
      trackEvent(inviteCode);
      if (inviteCode) {
        copyToClipboard(inviteCode as string);
      }
    },
    [searchParams]
  );

  return (
    <div className="min-h-screen flex items-center bg-gradient-radial flex-col relative overflow-hidden pb-32">
      <HeaderSection />
      <ParticlesBackground />
      <MainSection />
      <ScreenSection />
      {/* <CloudSection /> */}
      {/* <EarnSection /> */}
      {/* <FeatureSection /> */}
      {/* <MarqueeDemo /> */}
      {/* <RetroGridDemo /> */}
      <FixedBottomBar />
    </div>
  );
};

export default HomePage;
